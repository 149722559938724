import modal from "./modules/modal"
import submitForm from "./modules/form"
import menu from "./modules/menu"
import menuBackground from "./modules/menuBg"
import scroll from "./modules/scroll"
import cookies from "./modules/cookies"

window.addEventListener("DOMContentLoaded", () => {
    try{
        const videoFirst=document.querySelector(".promo-video")
        videoFirst.play()
        videoFirst.muted = true;
    }
    catch {
    }
    cookies();
  
    submitForm(".main form");
    submitForm(".footer form")
    submitForm(".modal-form")
    modal(".modal", ".modal-cross", ".promo-contacts__callback")
    modal(".modal", ".modal-cross", ".footer .promo-contacts__callback")
    modal(".modal-policy__wrapper", ".modal-cross", ".footer .modal-policy")
    menu(".promo-humb",".menu-wrapper", ".menu-item" )
    menuBackground(".promo-header")
    scroll("#header");
    scroll("#main");
    scroll("#info");
    scroll("#footer")

})