export default function submitForm(formSelector) {
    const form = document.querySelector(formSelector),
        modal = document.querySelector(".modal"),
        input = form.querySelectorAll("input"),
        modalForm = document.querySelector(".modal-thanks"),
        modalThanksText = modalForm.querySelector(".modal-text")
    

    form.addEventListener("submit", (e) => {
        e.preventDefault()
        let errors = false;
        input.forEach(inp => {
            inp.addEventListener('change', () => {
                inp.style.border = "";
            });
        });
        input.forEach(item => {
            if (item.value.length == 0 || item.value == " ") {
                item.style.border = "1px solid red";
                errors = true;
            } else {
                item.style.border = "2px solid #C5C5C5";
                errors = false
            }
        });
        if (!errors) {
            const dataForm = new FormData(form);
            fetch("mailer/smart.php", {
                method: "POST",
                body: dataForm
            }).then((res) => {
                if (!res.ok) {
                    modalThanksText.innerText = "Щось пішло не так..."
                    return
                }
            }).catch(() => {
                modalThanksText.innerText = "Щось пішло не так..."
            }).finally(() => {
                form.reset()
                modalForm.classList.add("show")
                try {
                    closeModal(".modal")
                } catch { }
                
                setTimeout(() => {
                    closeModal(".modal-thanks");
                }, 3000);
            })
        }
    })
    function closeModal(selector) {
        const form = document.querySelector(selector);
        form.classList.remove("show");
        document.body.style.overflow = "";
   
    }
    modalForm.addEventListener("click", (e) => {
        if (e.target == modalForm || e.target.getAttribute('data-close') == "") {
            closeModal(".modal-thanks");
        }
    })
}



